// extracted by mini-css-extract-plugin
export var carousel = "Slider-module--carousel--VoAG0";
export var display = "Slider-module--display--KRh4Y";
export var innerWrapper = "Slider-module--innerWrapper--0k0qW";
export var lowerLeft = "Slider-module--lowerLeft--dwowI";
export var lowerRight = "Slider-module--lowerRight--i6e5p";
export var slideText = "Slider-module--slideText--6nAKW";
export var slideTextContainer = "Slider-module--slideTextContainer--K5sn0";
export var textBlack = "Slider-module--textBlack--2Dlya";
export var textWhite = "Slider-module--textWhite--dUuvq";
export var upperLeft = "Slider-module--upperLeft--Hla3v";
export var upperRight = "Slider-module--upperRight--gjoN-";