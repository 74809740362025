import * as React from 'react';
import { graphql } from 'gatsby';

import { Metadata } from '@mangoart/gatsby-ui/components/Metadata';
import Slider from '@mangoart/gatsby-ui/components/ezagrar/EZAgrarSlider';
import TeaserText from '@mangoart/gatsby-ui/components/ezagrar/TeaserText/TeaserText';
import TeaserBoxes from '@mangoart/gatsby-ui/components/ezagrar/TeaserBoxes/TeaserBoxes';
import Slugger from '@mangoart/gatsby-ui/helper/Slugger';
import DefaultLayout from '../layout/DefaultLayout';

const IndexPage = ({ data, location }) => {
  const { slides, siteData, pageData, news } = data;
  const { siteMetadata } = siteData;
  const { metadata, opengraph } = pageData;
  function transformDate(dateString) {
    // format dd.mm.yyy
    const parts = dateString.split('.');
    return new Date(parts[2], parseInt(parts[1]) - 1, parts[0]);
  }
  function sortByDate(entry1, entry2) {
    return transformDate(entry2.node.date).getTime() - transformDate(entry1.node.date).getTime();
  }

  function filterExpired(node) {
    if (node.node.expirationdate <= 0) {
      return node;
    }

    return transformDate(node.node.expirationdate).getTime() < new Date().getTime() ? null : node;
  }

  const teaserBoxes = news.edges
    .sort(sortByDate)
    .filter(filterExpired)
    .map(({ node }) => {
      return {
        node: {
          id: node.id,
          title: node.title,
          published: true,
          teaser: node.teaser,
          alttext: '',
          image: {
            ...node.teaserimage,
          },
          actionlabel: 'Weiterlesen',
          link: `/news/${Slugger.slug(node.title)}`,
        },
      };
    })
    .filter((item, index) => index < 4);

  return (
    <DefaultLayout>
      <Metadata
        data={metadata}
        opengraphData={opengraph}
        defaultTitle={siteMetadata.title}
        canonicalSiteUrl={siteMetadata.canonicalSiteUrl}
        path={location.pathname}
      />
      <Slider data={slides.edges} />
      <TeaserText
        data={{
          title: 'Fendt ist nicht nur ein Name, Fendt ist eine Überzeugung.',
          text: 'Denn mit Fendt Traktoren und Erntemaschinen erreichen Landwirte und Lohnunternehmer ihre Ziele schneller und mit mehr Ertrag. Darum ist es unser täglicher Anspruch, die hohen Anforderungen unserer Kunden zu erfüllen, sogar zu übertreffen, und ihnen dort zum Erfolg zu verhelfen, wo alles beginnt: am Schnittpunkt zwischen Himmel und Erde, wo Natur und landwirtschaftliches Know-how aufeinander treffen. Hier profitieren Fendt Fahrer direkt von den effizienten Technologien zur Steigerung der Wirtschaftlichkeit – rentabel und nachhaltig – für die Erzeugung von Grundnahrungs- und Futtermitteln, natürlichen Rohstoffen und Energie.',
          link: null,
          label: null,
        }}
      />
      <TeaserBoxes data={teaserBoxes} />
    </DefaultLayout>
  );
};

export default IndexPage;

export const IndexPageQuery = graphql`
  query IndexPageQuery {
    siteData: site {
      id
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }

    news: allCockpitNews(filter: { published: { eq: true } }, sort: { fields: date, order: DESC }) {
      edges {
        node {
          id
          title
          teaser
          date
          expirationdate
          teaserimage {
            childImageSharp {
              gatsbyImageData(width: 340, aspectRatio: 2)
            }
          }
          images {
            alttext
            image {
              childImageSharp {
                gatsbyImageData(width: 340, aspectRatio: 2)
              }
            }
          }
        }
      }
    }

    slides: allCockpitSlides(filter: { published: { eq: true } }, sort: { fields: order, order: ASC }) {
      edges {
        node {
          id
          title
          highlighted_line
          position
          text_color
          text_line_1
          text_line_2
          mobile_text_line_1
          mobile_text_line_2
          image {
            id
            childImageSharp {
              gatsbyImageData(width: 1600)
            }
          }
        }
      }
    }

    teaserboxes: allCockpitTeaserbox {
      edges {
        node {
          id
          title
          published
          teaser
          alttext
          image {
            id
            childImageSharp {
              gatsbyImageData
            }
          }
          actionlabel
        }
      }
    }
    pageData: cockpitPageHomepage {
      metadata {
        title
        metakeywords
        metadescription
      }
      opengraph {
        title
        description
        type
        image {
          childImageSharp {
            gatsbyImageData(width: 1300, height: 630)
          }
        }
      }
    }
  }
`;
