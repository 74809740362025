const slugify = require('slugify');

const defaultParameters = {
  replacement: '-',
  remove: /[*+~.,()'"!?:@²̈–/]/g,
  lower: true,
};

// add new characters to charMap
slugify.extend({
  '☢': 'radiocative',
  '&': 'und',
  Ä: 'Ae',
  ä: 'ae',
  Ö: 'Oe',
  ö: 'oe',
  Ü: 'Ue',
  ü: 'ue',
  ß: 'ss',
});

module.exports = {
  slug(string) {
    return slugify(string, defaultParameters);
  },
  uniqueSlug(string, id) {
    return `${slugify(string, defaultParameters)}-${id}`;
  },
};
