import React from 'react';
import MultiClamp from 'react-multi-clamp';

import { Button } from '../Button';

import * as styles from './TeaserBox.module.css';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

TeaserBox.propTypes = {};
TeaserBox.defaultProps = {};

function TeaserBox({ data }) {
  const { id, link, image, title, teaser, alttext, actionlabel } = data;

  return (
    <div className={styles.TeaserBox} key={id}>
      <div className={styles.imageContainer}>
        <GatsbyImage imgStyle={{ height: 170 }} style={{ height: 170 }} alt={alttext} image={getImage(image)} />
      </div>
      <div className={styles.content}>
        <h4>{title}</h4>
        <p>
          <MultiClamp ellipsis="..." clamp={3}>
            {teaser || ' '}
          </MultiClamp>
        </p>
        <Button to={link} icon="arrow-right">
          {actionlabel}
        </Button>
      </div>
    </div>
  );
}

export default TeaserBox;
