import React from 'react';

import { Button } from '../Button';

import * as styles from './TeaserText.module.css';

export default function TeaserText({ data }) {
  const { title, text, link, linkLabel } = data;
  return (
    <div className={styles.content}>
      <h2>{title}</h2>
      <p>{text}</p>
      {link && linkLabel && <Button to={link}>{linkLabel}</Button>}
    </div>
  );
}
